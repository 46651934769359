import React, { useEffect, useState } from 'react'
import { MinutesEntryDialog } from '../components/MinutesEntryDialog'
import { TimedTests } from '../types'
import { useSelectedUser } from '../components/UserBase'
import { useTimer } from '../contexts/TimerContext'
import { Tester } from './Tester'

export const TesterWrapper = (): React.ReactNode => {
  const { startTimer } = useTimer()

  const { selectedUserState } = useSelectedUser()
  const [selectedUser] = selectedUserState

  const [hasTimedTestSetting, setHasTimedTestSetting] = useState<boolean | undefined>(undefined)
  const [isMinutesEntryDialogVisible, setMinutesEntryDialogVisibility] = useState<boolean>(false)
  const [testSessionDurationS, setTestSessionDurationS] = useState<number>()
  const [isTesterVisible, setIsTesterVisibility] = useState<boolean>(false)

  useEffect(() => {
    setHasTimedTestSetting(selectedUser.settings.find(s => s.code === TimedTests.code) != null)
  }, [selectedUser, setHasTimedTestSetting])

  useEffect(() => {
    setMinutesEntryDialogVisibility(hasTimedTestSetting)
    if (hasTimedTestSetting ?? true) {
      setIsTesterVisibility(true)
    }
  }, [hasTimedTestSetting, setMinutesEntryDialogVisibility, setIsTesterVisibility])

  useEffect(() => {
    if (testSessionDurationS != null) {
      startTimer(testSessionDurationS)
    }
  }, [testSessionDurationS])

  const handleMinutesEntrySkipClick = (): void => {
    setMinutesEntryDialogVisibility(false)
    setIsTesterVisibility(true)
  }

  const handleMinutesEntryStartClick = (testSessionMinutes: number): void => {
    setTestSessionDurationS(testSessionMinutes * 60)
    setMinutesEntryDialogVisibility(false)
    setIsTesterVisibility(true)
  }

  if (isMinutesEntryDialogVisible) {
    return <MinutesEntryDialog
      show={true}
      onHide={handleMinutesEntrySkipClick}
      onSubmit={handleMinutesEntryStartClick} />
  }

  if (isTesterVisible) {
    return <Tester
      testSessionDurationS={testSessionDurationS}
      showTestSessionTimer={testSessionDurationS != null} />
  }
}
