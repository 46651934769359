export const LOCAL_STORAGE_USERS_KEY = 'users'

export const QUESTION_DISPLAY_MILLISECONDS = 3000
export const QUESTION_CHECK_ANSWER_MILLISECONDS = 8000
export const QUESTION_CLEAR_RESULT_MILLISECONDS = 9000
export const SHOW_CORRECT_RESULT_MILLISECONDS = 3000

export const TOOLTIP_HIDE_MILLISECONDS = 400
export const TOOLTIP_SHOW_MILLISECONDS = 250

export const NEW_USER_ID_SENTINEL = '-1'

export const DELETE_ANSWER_CHARACTER_SENTINEL = '-1'

export const MIN_NON_DECIMAL_NUMBER_BASE = 0
export const MAX_NON_DECIMAL_NUMBER_BASE = 2

export const USER_TEST_RESULTS_TO_DISPLAY = 5
export const MAX_RESULTS_PER_TEST_TYPE: number = 20

export const DECIMAL_POINT = '.'

export const DATABASE_VERSION: number = 1

export const NUMBER_CORRECT_TO_HEAR_STREAK_SOUND_EFFECT: number = 10

export const COUNTDOWN_TIMER_INCREMENT_MS: number = 500
export const SESSION_COUNTDOWN_TIMER_INCREMENT_MS: number = 1000
