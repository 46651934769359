import React, { useEffect, useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { USER_TEST_RESULTS_TO_DISPLAY } from '../constants'
import { CodeToTestTypeMap, Correct, type User, type UserTestResult } from '../types'
import i18n from 'i18next'
import { format } from 'date-fns'
import { loadDateFnsLocale } from '../utils/loadDateFnsLocale'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './UserStats.module.css'

interface UserStatsProps {
  selectedUser: User
}

export const UserStats = ({ selectedUser }: UserStatsProps): React.ReactNode => {
  const { t } = useTranslation()
  const [dateFnsLocale, setDateFnsLocale] = useState<locale | null>(null)

  useEffect(() => {
    const loadLocale = async (): Promise<void> => {
      const locale = await loadDateFnsLocale(i18n.language)
      console.log(`Loaded locale ${locale.default.code}`)
      setDateFnsLocale(locale.default) // Set the loaded locale
    }

    loadLocale()
  }, [i18n.language]) // Reload if the language changes

  return (
    <>
      <div className={styles.userStats}>
        {Object.keys(selectedUser.testTypeResults).map((testTypeCode: keyof typeof CodeToTestTypeMap) => {
          const code = CodeToTestTypeMap.get(testTypeCode)
          const description = code?.desc || testTypeCode // Fallback to testTypeCode
          return (
            <Col key={testTypeCode}>
              <Card className={styles.testTypeCard}>
                <Card.Body className={styles.testTypeCardBody}>
                  <Card.Title className={styles.testTypeCardTitle}>
                    {t(description)}
                  </Card.Title>
                  <div className={styles.testTypeScore}>
                    {t('Score')} = {
                      selectedUser.testTypeScores2?.[testTypeCode] ?? t('N/A')
                    }
                  </div>
                  {selectedUser.testTypeResults[testTypeCode]
                    .slice(-USER_TEST_RESULTS_TO_DISPLAY)
                    .reverse()
                    .map((utr: UserTestResult, index) => (
                      <div className={styles.userTestResult} key={`${testTypeCode}-${index}`}>
                        <div className={styles.userTestResultDate}>
                          {dateFnsLocale &&
                            format(utr.testDate, 'P', { locale: dateFnsLocale })}
                        </div>
                        <div className={styles.userTestResultQuestion}>
                          {utr.question}
                        </div>
                        <div className={styles.userTestResultAnswer}>
                          {utr.result.code === Correct.code
                            ? (
                            <FontAwesomeIcon
                              className={styles.userTestResultCorrect}
                              icon={faCheck}
                            />
                              )
                            : (
                            <FontAwesomeIcon
                              className={styles.userTestResultIncorrect}
                              icon={faXmark}
                            />
                              )}
                        </div>
                      </div>
                    ))}
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </div>
    </>
  )
}
