export const loadDateFnsLocale = async (lang): Promise<locale> => {
  switch (lang) {
    case 'fr':
      return await import('date-fns/locale/fr')
    case 'es':
      return await import('date-fns/locale/es')
    case 'it':
      return await import('date-fns/locale/it')
    // Add more cases for other languages you support
    default:
      return await import('date-fns/locale/en-US') // Default language
  }
}
