export const toggleFullscreen = (): void => {
  const hasFullscreenElement = document.fullscreenElement != null ||
      document.webkitFullscreenElement != null ||
      document.mozFullScreenElement != null ||
      document.msFullscreenElement != null
  console.log('hasFullscreenElement', hasFullscreenElement)
  if (!hasFullscreenElement) {
    document.documentElement.requestFullscreen().catch((e) => {
      console.error(`Error attempting to enable full-screen mode: ${JSON.stringify(e)})`)
    })
  } else {
    document.exitFullscreen().catch(err => {
      console.error(`Error exiting fullscreen: ${err.message}`)
    })
  }
}
