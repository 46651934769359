import React, { useEffect, useState } from 'react'
import { isBefore, startOfDay, subDays } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { produce } from 'immer'
import {
  MAX_RESULTS_PER_TEST_TYPE,
  QUESTION_DISPLAY_MILLISECONDS,
  QUESTION_CHECK_ANSWER_MILLISECONDS,
  QUESTION_CLEAR_RESULT_MILLISECONDS,
  SHOW_CORRECT_RESULT_MILLISECONDS
} from '../constants'
import { ShowResult } from '../components/ShowResult'
import { StatusBar } from '../components/StatusBar'
import { TestMap } from '../components/tests/Tests'
import {
  type Code,
  CodeToTestTypeMap,
  Correct,
  Incorrect,
  type OnCompleteResult,
  type UserTestResult
} from '../types'
import { useSelectedUser } from '../components/UserBase'
import { Header } from '../components/Header'
import { getAdjustedMilliseconds } from '../utils/getAdjustedMilliseconds'
import { getRandomNumber } from '../utils/getRandomNumber'
import { saveUsers } from '../utils/saveUsers'
// @ts-expect-error(import of css styles for transpilation)
import styles from './Tester.module.css'

interface TesterProps {
  showTestSessionTimer: boolean
}

export const Tester = ({ showTestSessionTimer = false }: TesterProps): React.ReactNode => {
  const navigate = useNavigate()

  const { usersState, selectedUserState } = useSelectedUser()
  const [users] = usersState
  const [selectedUser, setSelectedUser] = selectedUserState

  const [testType, setTestType] = useState<string>()

  const [elapsedTimeMs, setElapsedTimeMs] = useState(0)
  const [isShowQuestion, setIsShowQuestion] = useState(false)
  const [isClearQuestion, setIsClearQuestion] = useState(false)
  const [isCheckAnswer, setIsCheckAnswer] = useState(false)
  const [isClearResult, setIsClearResult] = useState(false)
  const [testStartTime, setTestStartTime] = useState<Date>()
  const [testResult, setTestResult] = useState<Code | undefined>()
  const [onCompleteResult, setOnCompleteResult] = useState<OnCompleteResult | undefined>()

  useEffect(() => {
    if (testType != null) {
      // Set up a timer interval that triggers every x milliseconds
      const intervalId = setInterval(() => {
        setElapsedTimeMs(prevElapsedTimeMs => prevElapsedTimeMs + 100)
      }, 100)

      // Clean up the interval when the component is unmounted or when the timer is stopped
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [testType, setElapsedTimeMs])

  useEffect(() => {
    if (testType != null) {
      const questionClearResultMs = getAdjustedMilliseconds(selectedUser, testType, QUESTION_CLEAR_RESULT_MILLISECONDS)
      const questionCheckAnswerMs = getAdjustedMilliseconds(selectedUser, testType, QUESTION_CHECK_ANSWER_MILLISECONDS)
      const questionDisplayMs = getAdjustedMilliseconds(selectedUser, testType, QUESTION_DISPLAY_MILLISECONDS)
      if (elapsedTimeMs > questionClearResultMs && !isClearResult) {
        setIsClearResult(true)
      } else if (elapsedTimeMs > questionCheckAnswerMs && !isCheckAnswer) {
        setIsCheckAnswer(true)
      } else if (elapsedTimeMs > questionDisplayMs && !isClearQuestion) {
        setIsClearQuestion(true)
      } else if (elapsedTimeMs > 50 && !isShowQuestion) {
        setIsShowQuestion(true)
      }
    }
  }, [testType, elapsedTimeMs, setIsClearResult, setIsCheckAnswer, setIsClearQuestion, setIsShowQuestion])

  useEffect(() => {
    if (isClearResult) {
      initializeState()
      startTest()
    }
  }, [isClearResult])

  const initializeState = (): void => {
    setTestResult(undefined)
    setTestType(undefined)
    setTestStartTime(undefined)
    setIsShowQuestion(false)
    setIsClearQuestion(false)
    setIsCheckAnswer(false)
    setIsClearResult(false)
    setElapsedTimeMs(0)
  }

  const startTest = (): void => {
    setTestStartTime(new Date())
    const testTypeIndex = getRandomNumber(0, selectedUser.testTypes.length - 1)
    const tt: Code = selectedUser.testTypes[testTypeIndex]

    // Check if the score exists and update state if it doesn't
    if (!(tt.code in selectedUser.testTypeScores2)) {
      setSelectedUser(prevUser =>
        produce(prevUser, draft => {
          draft.testTypeScores2[tt.code] = 1
        })
      )
    }

    // Check if the results exists and update state if it doesn't
    if (!(tt.code in selectedUser.testTypeResults)) {
      setSelectedUser(prevUser =>
        produce(prevUser, draft => {
          draft.testTypeResults[tt.code] = []
        })
      )
    }

    setTestType(tt.code)
  }

  useEffect(() => {
    startTest()
  }, [])

  const handleStopClick = (): void => {
    initializeState()
    navigate('/users')
  }

  const processTestStreak = (user: User): void => {
    user.currentTestStreak = (user.currentTestStreak ?? 0) + 1
    if ((user.currentTestStreak ?? 0) > (user.topTestStreak ?? 0)) {
      user.topTestStreak = user.currentTestStreak
    }
  }

  const processDayStreak = (user: User): void => {
    // Normalize dates to the start of their respective days
    const startOfLastPlayDate = startOfDay(user.lastPlayDate ?? subDays(new Date(), 1))
    const startOfTestStartTime = startOfDay(testStartTime)

    if (isBefore(startOfLastPlayDate, startOfTestStartTime)) {
      user.lastPlayDate = testStartTime
      user.currentDayStreak = (user.currentDayStreak ?? 0) + 1
    }
    if ((user.currentDayStreak ?? 0) > (user.topDayStreak ?? 0)) {
      user.topDayStreak = user.currentDayStreak
    }
  }

  const handleOnComplete = (onCompleteResult: OnCompleteResult): void => {
    if (onCompleteResult.answer.length === 0) {
      return
    }

    const testResult = onCompleteResult.answer === onCompleteResult.userAnswer
      ? Correct
      : Incorrect

    // @ts-expect-error(testType will be defined here as string)
    const tt = CodeToTestTypeMap.get(testType)
    const currentScore = selectedUser.testTypeScores2[testType]
    let newScore = currentScore + testResult.numericValue1
    if (newScore <= 0) {
      newScore = 1
    }

    const userTestResult: UserTestResult = {
      testType: tt,
      testDate: testStartTime,
      userScore: newScore,
      question: onCompleteResult.question,
      answer: onCompleteResult.answer,
      userAnswer: onCompleteResult.userAnswer,
      result: testResult
    }

    // Make sure we only display result for QUESTION_CLEAR_RESULT_MILLISECONDS even for short circuit results
    // If the answer is incorrect, show the result for longer
    let elapsedTimeToShowResult = getAdjustedMilliseconds(selectedUser, testType, QUESTION_CHECK_ANSWER_MILLISECONDS)
    if (testResult === Incorrect) {
      elapsedTimeToShowResult -= getAdjustedMilliseconds(selectedUser, testType, SHOW_CORRECT_RESULT_MILLISECONDS)
    }

    setTestResult(testResult)
    setOnCompleteResult(onCompleteResult)
    setElapsedTimeMs(elapsedTimeToShowResult)

    setSelectedUser(produce(selectedUser, draft => {
      if (testResult === Correct) {
        processTestStreak(draft)
        processDayStreak(draft)
      } else {
        draft.currentTestStreak = 0
      }

      draft.testTypeScores2[testType] = newScore
      draft.testTypeResults[testType].push(userTestResult)
      draft.testTypeResults[testType] = draft.testTypeResults[testType].slice(-MAX_RESULTS_PER_TEST_TYPE)

      saveUsers(users, draft)

      console.log(
        'testType', testType,
        'result', testResult.code,
        'score', newScore,
        'question', onCompleteResult.question,
        'answer', onCompleteResult.answer,
        'userAnswer', onCompleteResult.userAnswer)
    }))
  }

  if (testType != null) {
    if (testResult != null) {
      return <ShowResult
          selectedUser={selectedUser}
          onCompleteResult={onCompleteResult}
          testResult={testResult}
          testType={testType} />
    }

    // @ts-expect-error(testType will be a string here)
    const TestComponent = TestMap[testType]
    const questionCheckAnswerMs = getAdjustedMilliseconds(selectedUser, testType, QUESTION_CHECK_ANSWER_MILLISECONDS)

    return (
      <>
        <Header testDurationMs={questionCheckAnswerMs} showTestSessionTimer={showTestSessionTimer} />
        <div className={styles.doTestContainer}>
          <TestComponent
            selectedUser={selectedUser}
            selectedUserTestTypeScore={selectedUser.testTypeScores2[testType]}
            isShowQuestion={isShowQuestion}
            isClearQuestion={isClearQuestion}
            isCheckAnswer={isCheckAnswer}
            onComplete={handleOnComplete}
            onStop={handleStopClick} />
        </div>
        <StatusBar selectedUser={selectedUser} testType={testType} />
      </>
    )
  }
}
