import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { AppRouter } from './components/AppRouter'
import './i18n'
import './App.module.css'

// Add the solid icons to the library
library.add(fas)

export const App = (): React.ReactNode => {
  return (
    <>
      <AppRouter />
    </>
  )
}
