import React from 'react'
import MediaQuery from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { CountdownCircle } from './CountdownCircle'
import { TestSessionTimer } from './TestSessionTimer'
// @ts-expect-error(import of css styles for transpilation)
import styles from './Header.module.css'

interface HeaderProps {
  testDurationMs: number | undefined
  showTestSessionTimer: boolean
}

export const Header: React.FC<HeaderProps> = ({ testDurationMs, showTestSessionTimer = false }): React.ReactNode => {
  const navigate = useNavigate()

  const handleBrandingClick = (user): void => {
    navigate('/')
  }

  const hasTestDuration = testDurationMs != null

  return (
    <div className={styles.header}>
      { /* no durations, show full branding image */ }
      { !hasTestDuration && !showTestSessionTimer &&
        <img
          className={styles.branding}
          src="/Mathniac-branding.png"
          alt="Mathniac-branding"
          onClick={handleBrandingClick}
        />
      }

      { /* yes test duration, yes session duration, show just logo */ }
      { hasTestDuration && showTestSessionTimer &&
        <>
          <img
            className={styles.brandingMobile}
            src="/Mathniac.png"
            alt="Mathniac-branding"
            onClick={handleBrandingClick}
          />
        </>
      }

      { /* yes test duration, no session duration, show full branding image for larger screens, otherwise just logo */ }
      { /* no test duration, yes session duration, show full branding image for larger screens, otherwise just logo */ }
      { ((!hasTestDuration && showTestSessionTimer) || (hasTestDuration && !showTestSessionTimer)) &&
        <>
          <MediaQuery maxWidth={767}>
            <img
              className={styles.brandingMobile}
              src="/Mathniac.png"
              alt="Mathniac-branding"
              onClick={handleBrandingClick}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <img
              className={styles.branding}
              src="/Mathniac-branding.png"
              alt="Mathniac-branding"
              onClick={handleBrandingClick}
            />
          </MediaQuery>
        </>
      }

      { showTestSessionTimer && <TestSessionTimer /> }
      { testDurationMs != null && <CountdownCircle durationMs={testDurationMs} /> }
    </div>
  )
}
