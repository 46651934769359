import React, { useEffect, useRef, useState } from 'react'
import useSound from 'use-sound'
import { useTimer } from '../contexts/TimerContext'
import styles from './TestSessionTimer.module.css' // Import CSS module

const TIMER_BACKGROUND_GREEN_SECONDS = 5 * 60
const TIMER_BACKGROUND_YELLOW_SECONDS = 1 * 60

export const TestSessionTimer: React.FC<TestSessionTimerProps> = () => {
  const { timeLeft } = useTimer()
  const [playSchoolBell] = useSound('/school-bell.mp3')
  const [playedSchoolBell, setPlayedSchoolBell] = useState(false)

  const playedSchoolBellRef = useRef(playedSchoolBell)

  useEffect(() => {
    playedSchoolBellRef.current = playedSchoolBell
  }, [playedSchoolBell])

  useEffect(() => {
    if (timeLeft === 0 && !playedSchoolBellRef.current) {
      setPlayedSchoolBell(true)
      playSchoolBell()
    }
  }, [timeLeft, playSchoolBell, playedSchoolBellRef, setPlayedSchoolBell])

  // Format time left into minutes and seconds
  const formatTimeLeft = (time: number): string => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
  }

  // Determine the appropriate class based on time left
  const getTimerClass = (): string => {
    if (timeLeft >= TIMER_BACKGROUND_GREEN_SECONDS) {
      return `${styles.timer} ${styles.timerGreen}`
    } else if (timeLeft >= TIMER_BACKGROUND_YELLOW_SECONDS) {
      return `${styles.timer} ${styles.timerYellow}`
    } else {
      return `${styles.timer} ${styles.timerRed}`
    }
  }

  return (
    <div className={styles.container}>
      <div className={getTimerClass()}>
        {formatTimeLeft(timeLeft)}
      </div>
    </div>
  )
}
