import React from 'react'
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TOOLTIP_SHOW_MILLISECONDS, TOOLTIP_HIDE_MILLISECONDS } from '../constants'
import { CodeToSettingMap, SortedSettings } from '../types'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './SettingsSelection.module.css'

interface SettingsSelectionProps {
  settingCheckboxStates: Record<string, boolean>
  onCheck: (setting: string) => void
}

export const SettingsSelection = ({ onCheck, settingCheckboxStates }: SettingsSelectionProps): React.ReactNode => {
  const { t } = useTranslation()

  const renderTooltip = (props, setting: string): React.ReactNode => (
    <Tooltip id={`${setting}-tooltip`} {...props}>
      {t(`${CodeToSettingMap.get(setting).desc ?? CodeToSettingMap.get(setting).code} Tooltip`)}
    </Tooltip>
  )

  return (
    <div className={styles.groupBox}>
      <div className={styles.groupBoxLabel}>{t('Settings')}</div>
      <div className={styles.userCardSettings}>
        {SortedSettings.map((setting) => (
          <Col className={styles.userCardSettingCheckbox} key={setting} xs={6} md={3}>
            <OverlayTrigger
              placement="right"
              delay={{ show: TOOLTIP_SHOW_MILLISECONDS, hide: TOOLTIP_HIDE_MILLISECONDS }}
              overlay={(props) => renderTooltip(props, setting)}
            >
              <div className={styles.userCardSettingCheckbox} style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  key={setting}
                  type="checkbox"
                  readOnly
                  id={`checkbox-${setting}`}
                  checked={settingCheckboxStates[setting]}
                  onChange={() => { onCheck(setting) }}
                />
                <label htmlFor={`checkbox-${setting}`}>
                  {t(CodeToSettingMap.get(setting).desc ?? CodeToSettingMap.get(setting).code)}
                </label>
              </div>
            </OverlayTrigger>
          </Col>
        ))}
      </div>
    </div>
  )
}
