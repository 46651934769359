import React, { useEffect, useState } from 'react'
import { COUNTDOWN_TIMER_INCREMENT_MS } from '../constants'
import { useTranslation } from 'react-i18next'
import styles from './CountdownCircle.module.css' // Import CSS module

interface CountdownCircleProps {
  durationMs: number
}

export const CountdownCircle: React.FC<CountdownCircleProps> = ({ durationMs }) => {
  const { t } = useTranslation()
  const [timeLeft, setTimeLeft] = useState(durationMs)

  useEffect(() => {
    // Initialize the timer
    setTimeLeft(durationMs)
  }, [durationMs])

  useEffect(() => {
    if (timeLeft <= 0) {
      return // If no time left, do nothing
    }

    // Set up the interval
    const intervalId = setInterval(() => {
      setTimeLeft(prevTimeLeft => Math.max(prevTimeLeft - COUNTDOWN_TIMER_INCREMENT_MS, 0))
    }, COUNTDOWN_TIMER_INCREMENT_MS)

    return () => { clearInterval(intervalId) }
  }, [timeLeft])

  const circumference = 2 * Math.PI * 40
  const strokeDashoffset = (circumference * timeLeft / durationMs) // Calculate the dash offset

  // Define thresholds for color changes
  const thirdOfDuration = durationMs / 3
  let colorId
  if (timeLeft > 2 * thirdOfDuration) {
    colorId = 'greenGradient' // First third of the duration
  } else if (timeLeft > thirdOfDuration) {
    colorId = 'yellowGradient' // Second third of the duration
  } else {
    colorId = 'redGradient' // Last third of the duration
  }

  return (
    <svg width="5em" height="5em" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="40"
        className={styles.baseCircle}
      />
      <circle
        cx="50"
        cy="50"
        r="40"
        className={styles.activeCircle}
        stroke={`url(#${colorId})`}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        transform="rotate(-90 50 50)"
      />
      <defs>
        <linearGradient id="greenGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#02db02" />  {/* Light green */}
          <stop offset="100%" stopColor="#02db02" /> {/* Dark green */}
        </linearGradient>
        <linearGradient id="yellowGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#f7e705" />  {/* Light yellow */}
          <stop offset="100%" stopColor="#f7e705" /> {/* Dark yellow */}
        </linearGradient>
        <linearGradient id="redGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#f70000" />  {/* Light red */}
          <stop offset="100%" stopColor="#f70000" /> {/* Dark red */}
        </linearGradient>
      </defs>
      <text
        x="50"
        y="55"
        textAnchor="middle"
        fontSize="18"
        className={styles.countdownText}
      >
        {Math.round(timeLeft / 1000).toFixed(1)}{t('Second Abbreviation')}
      </text>
    </svg>
  )
}
