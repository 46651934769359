import React from 'react'
import { Test } from './Test'
import { type TestProps } from '../../types'
import { getRandomNumber } from '../../utils/getRandomNumber'
import { useTranslation } from 'react-i18next'

export const Memory = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isClearQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  const { t } = useTranslation()

  const getQuestionAndAnswer = (): [string, string, string] => {
    const unprocessedDigits = Math.log(selectedUserTestTypeScore)
    const digits = unprocessedDigits < 1 ? 1 : Math.round(unprocessedDigits)
    const questionParts: number[] = []

    questionParts.push(getRandomNumber(1, 9))
    for (let i = 1; i < digits; i++) {
      questionParts.push(getRandomNumber(0, 9))
    }
    const question = questionParts.join('')
    const questionPrompt = t('Memory Question Prompt')
    const answer = question

    console.log(
      'unprocessedDigits', unprocessedDigits,
      'digits', digits,
      'question', question,
      'answer', answer)

    return [question, answer, questionPrompt]
  }

  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={isClearQuestion}
      isCheckAnswer={isCheckAnswer}
      willClearQuestion={true}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
