import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Landing } from '../pages/Landing'
import { NotFound } from '../pages/NotFound'
import { TesterWrapper } from '../pages/TesterWrapper'
import { UserDetails } from '../pages/UserDetails'
import { Users } from '../pages/Users'

export const AppRouter = (): React.ReactNode => {
  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route path="users" element={<Users />} />
      <Route path="user-details/:id" element={<UserDetails />} />
      <Route path="tester/:id" element={<TesterWrapper />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
