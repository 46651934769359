import React from 'react'
import { Test } from './Test'
import { type TestProps } from '../../types'
import { getMaxNumber } from '../../utils/getMaxNumber'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const CommonMultiples = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  const getQuestionAndAnswer = (selectedUserTestTypeScore: number, includeNegativeNumbers: boolean = false): [string, string] => {
    const maxNumber = getMaxNumber(selectedUserTestTypeScore)

    const multiplicand = getRandomNumber(0, maxNumber)
    let multiplier: number

    // Now pick a fixed factor multiplier or a random multiplier
    const problemType = getRandomNumber(0, 6)
    if (problemType === 0) {
      multiplier = 20
    } else if (problemType === 1) {
      multiplier = 50
    } else if (problemType === 2) {
      multiplier = 25
    } else if (problemType === 3) {
      multiplier = 40
    } else if (problemType === 4) {
      multiplier = 75
    } else {
      multiplier = getRandomNumber(0, maxNumber)
    }

    const question = multiplier.toString() + ' \u00D7 ' + multiplicand.toString()
    const answer = (multiplier * multiplicand).toString()

    console.log(
      'multiplier', multiplier,
      'multiplicand', multiplicand,
      'question', question,
      'answer', answer)

    return [question, answer]
  }

  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
