import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

void i18n
  // load translation using http -> see /public/locales. We will add locales in the next step
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      // e.g., public/locales/en/translation.json
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    fallbackLng: 'en', // use en if detected lng is not available
    ns: ['translation'], // default namespace, if you're using multiple namespaces, list them here
    defaultNS: 'translation',
    debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },
    nonExplicitSupportedLngs: true, // Add this line to support non-explicit language codes
    saveMissing: true,
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
      console.warn(
        `Missing key detected:
        Language: ${lng}
        Namespace: ${ns}
        Key: ${key}
        Fallback Value: ${fallbackValue}`
      )

      // Log the stack trace
      console.warn(new Error('Missing translation key stack trace').stack)
    }
  })

export default i18n
