import {
  CodeToTestTypeMap,
  type TestTypeScores,
  type TestTypeScore,
  type TestTypeScore2
} from '../types'

export const findHighestTestTypeScore = (testTypeScores: TestTypeScores): TestTypeScore => {
  const topTestTypeScore = Object.entries(testTypeScores).reduce((highest, [testType, score]): TestTypeScore2 => {
    if (score > highest.score) {
      return { testType, score }
    }
    return highest
  }, { testType: null, score: -Infinity })

  return topTestTypeScore.testType != null
    ? { testType: CodeToTestTypeMap.get(topTestTypeScore.testType as string), score: topTestTypeScore.score }
    : null
}
