import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Header } from '../components/Header'
import { useSavedUsers } from '../components/UserBase'
import { UserCard } from '../components/UserCard'
import { toggleFullscreen } from '../utils/toggleFullscreen'
// @ts-expect-error(import of css styles for transpilation)
import styles from './Users.module.css'

export const Users = (): React.ReactNode => {
  const navigate = useNavigate()
  const [users] = useSavedUsers()

  const handleStartClick = useCallback((user) => {
    navigate(`/tester/${user.id}`)
    toggleFullscreen()
  }, [navigate])

  const handleEditUserClick = useCallback((user) => {
    navigate(`/user-details/${user.id}`)
  }, [navigate])

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.userCards}>
          {users.map(user => (
            <UserCard
              key={user.id}
              user={user}
              onStartClick={handleStartClick}
              onEditClick={handleEditUserClick}
            />
          ))}
        </div>
      </div>
    </>
  )
}
