import React from 'react'
import { Test } from './Test'
import { type TestProps } from '../../types'
import { DECIMAL_POINT } from '../../constants'
import { getRandomNumber } from '../../utils/getRandomNumber'
import { roundToPowerOf10 } from '../../utils/roundToPowerOf10'
import { useTranslation } from 'react-i18next'

export const Rounding = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isClearQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  const { t } = useTranslation()

  const getQuestionAndAnswer = (): [string, string, string, number] => {
    const unprocessedDigits = Math.log(selectedUserTestTypeScore)
    // Need at least 2 digits for rounding test
    const digits = unprocessedDigits < 2 ? 2 : Math.round(unprocessedDigits)

    const questionParts: number[] = []
    // First number cannot be 0
    questionParts.push(getRandomNumber(1, 9))
    for (let i = 1; i < digits - 1; i++) {
      // None of the numbers can be 0
      questionParts.push(getRandomNumber(0, 9))
    }
    // Last number cannot be 0
    questionParts.push(getRandomNumber(1, 9))

    const questionWithNoDecimalPoint = questionParts.join('')

    const maxPowerOfTenToRoundTo = questionWithNoDecimalPoint.length - 1
    // Range is 1 to the number of digits - 1
    const powerOfTenToRoundTo = getRandomNumber(1, maxPowerOfTenToRoundTo)

    const includeDecimalPlaces = digits > 4 && getRandomNumber(0, 1) === 1

    let question = questionWithNoDecimalPoint
    let questionHighlightIndex = question.length - powerOfTenToRoundTo - 1
    let answer = roundToPowerOf10(+questionWithNoDecimalPoint, powerOfTenToRoundTo).toString()

    if (includeDecimalPlaces) {
      // Decimal place can be before the first number or any other number, except the last number,
      // because that would be a number with no decimal places. Have to subtract 2 from digits because
      // of a zero based index for arrays
      const decimalPlaceIndex = getRandomNumber(0, digits - 2)
      if (decimalPlaceIndex === 0) {
        question = `${DECIMAL_POINT}${question}`
        answer = `${DECIMAL_POINT}${answer}`
      } else {
        question = question.slice(0, decimalPlaceIndex) + '.' + question.slice(decimalPlaceIndex)
        answer = answer.slice(0, decimalPlaceIndex) + '.' + answer.slice(decimalPlaceIndex)
      }

      // If the decimal point comes before the question highlight index, move the highlight index
      // over 1 spot to account for decimal point
      questionHighlightIndex += decimalPlaceIndex <= questionHighlightIndex ? 1 : 0

      // Strip off trailing zeroes after decimal point
      answer = answer.replace(/0+$/, '')
      // Strip off trailing decimal point
      answer = answer.replace(/\.+$/, '')
    }

    console.log(
      'unprocessedDigits', unprocessedDigits,
      'digits', digits,
      'question', question,
      'questionHighlightIndex', questionHighlightIndex,
      'powerOfTenToRoundTo', powerOfTenToRoundTo,
      'answer', answer)

    return [question, answer, t('Rounding Question Prompt'), questionHighlightIndex]
  }

  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      isShowDecimalPoint={true}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
