import React from 'react'
import { Test } from './Test'
import { type TestProps } from '../../types'
import { getMaxDigits } from '../../utils/getMaxDigits'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const CloseToHundred = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  const getQuestionAndAnswer = (): [string, string] => {
    const maxDigit = getMaxDigits(selectedUserTestTypeScore)
    const randomDigit = getMaxDigits(0, maxDigit)

    if (getRandomNumber(0, 1) === 1) {
      const hundred = 100 * randomDigit
      const addend1 = getRandomNumber(0, hundred)
      const addend2 = getRandomNumber(0, 1) === 1
        ? hundred + randomDigit
        : hundred - randomDigit

      const question = addend1.toString() + ' + ' + addend2.toString()
      const answer = (addend1 + addend2).toString()

      console.log(
        'addend1', addend1,
        'addend2', addend2,
        'question', question,
        'answer', answer)

      return [question, answer]
    } else {
      const hundred = 100 * randomDigit
      const subtrahend = getRandomNumber(0, 1) === 1
        ? hundred + randomDigit
        : hundred - randomDigit
      const minuend = getRandomNumber(subtrahend, subtrahend + 100)

      const question = minuend.toString() + ' - ' + subtrahend.toString()
      const answer = (minuend - subtrahend).toString()

      console.log(
        'minuend', minuend,
        'subtrahend', subtrahend,
        'question', question,
        'answer', answer)

      return [question, answer]
    }
  }

  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
