import React from 'react'
import { Button, Card, Col } from 'react-bootstrap'
import { NEW_USER_ID_SENTINEL } from '../constants'
import { type User } from '../types'
import { UserStatsSummary } from './UserStatsSummary'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './UserCard.module.css'

interface UserCardProps {
  user: User
  onStartClick: (user: User) => void
  onEditClick: (user: User) => void
}

export const UserCard = ({ user, onStartClick, onEditClick }: UserCardProps): React.ReactNode => {
  const { t } = useTranslation()

  return (
    <Col key={user.id}>
      <Card className={styles.userCard}>
        <Card.Body className={styles.userCardBody}>
          <Card.Title className={styles.userCardTitle}>{user.name}</Card.Title>
          <UserStatsSummary selectedUser={user} />
          <div className={styles.userCardActions}>
            {user.id !== NEW_USER_ID_SENTINEL && user.testTypes.length > 0 && (
              <Button className={styles.userCardStartButton} variant='success' onClick={() => { onStartClick(user) }}>{t('Start')}</Button>
            )}
            <Button className={styles.userCardEditButton} variant='primary' onClick={() => { onEditClick(user) }}>{t('Edit')}</Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}
