import React from 'react'
import { Test } from './Test'
import { type TestProps } from '../../types'
import { getMaxNumber } from '../../utils/getMaxNumber'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const Powers = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  const getQuestionAndAnswer = (): [string, string] => {
    const maxNumber = getMaxNumber(selectedUserTestTypeScore)
    const base = getRandomNumber(1, maxNumber)
    const raise = getRandomNumber(0, 1) === 0 // Determines whether to raise or root
    const minExponent = raise ? 0 : 1
    const maxExponent = Math.round(1 / base * maxNumber)
    const exponent = getRandomNumber(minExponent, maxExponent)
    const power = base ** exponent

    let question, answer

    if (raise) {
      // Standard exponentiation
      question = `${base} ^ ${exponent}`
      answer = power.toString()
    } else {
      // Root operation using square root symbol for exponent 2 or "root" notation for others
      if (exponent === 2) {
        question = `√${power}`
      } else {
        question = `${exponent}√${power}` // This represents nth root using the notation n√x
      }
      answer = base.toString()
    }

    console.log(
      'base', base,
      'exponent', exponent,
      'power', power,
      'raise', raise,
      'question', question,
      'answer', answer)

    return [question, answer]
  }

  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
