import React from 'react'
import { Test } from './Test'
import { type TestProps } from '../../types'
import { getMaxNumber } from '../../utils/getMaxNumber'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const getQuestionAndAnswer = (selectedUserTestTypeScore: number, includeNegativeNumbers: boolean = false): [string, string] => {
  const maxNumber = getMaxNumber(selectedUserTestTypeScore)
  const multiplier = getRandomNumber(1, maxNumber)
  const multiplicand = getRandomNumber(0, maxNumber)
  let dividend = multiplier * multiplicand
  // Prevent division by zero
  let divisor = multiplier > multiplicand ? multiplier : multiplicand

  dividend *= includeNegativeNumbers ? -1 : 1
  divisor *= includeNegativeNumbers ? -1 : 1

  const question = dividend.toString() + ' ÷ ' + divisor.toString()
  const answer = (dividend / divisor).toString()

  console.log(
    'dividend', dividend,
    'divisor', divisor,
    'question', question,
    'answer', answer)

  return [question, answer]
}

export const Division = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
