import React, { useEffect, useRef, useState } from 'react'
import { DELETE_ANSWER_CHARACTER_SENTINEL } from '../../constants'
import { NumericKeypad } from '../NumericKeypad'
import { type TestProps } from '../../types'
// @ts-expect-error(import of css styles for transpilation)
import styles from './Test.module.css'

export const Test = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isClearQuestion,
  isCheckAnswer,
  willClearQuestion = false,
  isShowHexKeys = false,
  isShowDecimalPoint = false,
  onComplete,
  onStop,
  onGetQuestionAndAnswer
}: TestProps): React.ReactNode => {
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [questionPrompt, setQuestionPrompt] = useState('')
  const [questionHighlightIndex, setQuestionHighlightIndex] = useState<number | undefined>()
  const [userAnswer, setUserAnswer] = useState('')

  const onCompleteRef = useRef(onComplete)
  const isShowQuestionRef = useRef(isShowQuestion)

  // Keep refs up to date
  useEffect(() => {
    onCompleteRef.current = onComplete
    isShowQuestionRef.current = isShowQuestion
  }, [onComplete, isShowQuestion])

  useEffect(() => {
    if (isShowQuestion) {
      const [question, answer, questionPrompt, questionHighlightIndex] = onGetQuestionAndAnswer(selectedUserTestTypeScore)
      setQuestion(question)
      setQuestionPrompt(questionPrompt)
      if (questionHighlightIndex != null) {
        setQuestionHighlightIndex(questionHighlightIndex)
      }
      setAnswer(answer)

      isShowQuestionRef.current = isShowQuestion
    }
  }, [isShowQuestion, setQuestion, setAnswer, setQuestionPrompt, setQuestionHighlightIndex])

  // Short circuit onComplete on correct answer
  useEffect(() => {
    if (isShowQuestionRef.current && answer.length > 0 && userAnswer === answer) {
      onCompleteRef.current({ question, answer, userAnswer })
    }
  }, [userAnswer, isShowQuestionRef, onCompleteRef])

  useEffect(() => {
    if (isCheckAnswer) {
      onCompleteRef.current({ question, answer, userAnswer })
    }
  }, [isCheckAnswer, onCompleteRef])

  const handleKeyPress = (key: string): void => {
    setUserAnswer((prevUserAnswer) => key === DELETE_ANSWER_CHARACTER_SENTINEL
      ? prevUserAnswer.slice(0, -1)
      : prevUserAnswer + key)
  }

  const highlightCharacterInText = (text, charIndex, highlightClassName): React.ReactNode => {
    // Check if the character index is within the bounds of the question text
    if (charIndex < 0 || charIndex >= text.length) {
      return text // Return the original text if out of bounds
    }

    const partBefore = text.substring(0, charIndex)
    const charToHighlight = text[charIndex]
    const partAfter = text.substring(charIndex + 1)

    return (
      <React.Fragment>
        {partBefore}
        <span className={highlightClassName}>{charToHighlight}</span>
        {partAfter}
      </React.Fragment>
    )
  }

  if (isClearQuestion) {
    return (
      <div className={styles.questionContainer}>
        <div className={styles.userAnswer}>{userAnswer}</div>
        <NumericKeypad
          onKeyPress={handleKeyPress}
          isShowHexKeys={isShowHexKeys}
          isShowDecimalPoint={isShowDecimalPoint}
          onStop={onStop} />
      </div>
    )
  }

  if (isShowQuestion) {
    return (
      <>
        <div className={styles.questionContainer}>
          { questionPrompt != null && <div className={styles.questionPrompt}>{questionPrompt}</div> }
          <div className={styles.question}>
            <div className={styles.questionInlineText}>
              { questionHighlightIndex != null
                ? highlightCharacterInText(question, questionHighlightIndex, styles.highlight)
                : question }
            </div>
          </div>
          { !willClearQuestion && <div className={styles.userAnswer}>{userAnswer}</div> }
          { !willClearQuestion &&
            <NumericKeypad
              onKeyPress={handleKeyPress}
              isShowHexKeys={isShowHexKeys}
              isShowDecimalPoint={isShowDecimalPoint}
              onStop={onStop} /> }
        </div>
      </>
    )
  }
}
