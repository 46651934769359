import React from 'react'
import { type User } from '../types'
import { useTranslation } from 'react-i18next'
import { findHighestTestTypeScore } from '../utils/findHighestTestTypeScore'
// @ts-expect-error(import of css styles for transpilation)
import styles from './UserStatsSummary.module.css'

interface UserStatusSummaryProps {
  selectedUser: User
}

export const UserStatsSummary = ({ selectedUser }: UserStatusSummaryProps): React.ReactNode => {
  const { t } = useTranslation()

  const topTestTypeScore = findHighestTestTypeScore(selectedUser.testTypeScores2)

  const currentTestStreak = selectedUser.currentTestStreak ?? 0
  const currentDayStreak = selectedUser.currentDayStreak ?? 0

  const topTestStreak = selectedUser.topTestStreak ?? 0
  const topDayStreak = selectedUser.topDayStreak ?? 0

  return (
    <>
      {topTestTypeScore != null && (
          <div className={styles.testTypeScoreContainer}>
            <div className={styles.testTypeScoreCaption}>{t('Top score caption')}</div>
            <div className={styles.testTypeScore} key='testTypeScore'>
              {t('Top score', { testType: t(topTestTypeScore.testType.desc ?? topTestTypeScore.testType.code), score: topTestTypeScore.score })}
            </div>

            <div className={styles.topStreakCaption}>{t('Top streak')}</div>
            <div className={styles.topTestStreak} key='topStreak'>
              {t('Streaks', { testStreak: topTestStreak, dayStreak: topDayStreak })}
            </div>

            <div className={styles.currentStreakCaption}>{t('Current streak')}</div>
            <div className={styles.currentTestStreak} key='currentStreak'>
              {t('Streaks', { testStreak: currentTestStreak, dayStreak: currentDayStreak })}
            </div>
          </div>
      )}
    </>
  )
}
