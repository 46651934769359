import React from 'react'
import { Test } from './Test'
import { getQuestionAndAnswer as getQuestionAndAnswerAddition } from './Addition'
import { getQuestionAndAnswer as getQuestionAndAnswerDivision } from './Division'
import { getQuestionAndAnswer as getQuestionAndAnswerMultiplication } from './Multiplication'
import { getQuestionAndAnswer as getQuestionAndAnswerSubtraction } from './Subtraction'
import {
  AdditionOperation,
  DivisionOperation,
  MultiplicationOperation,
  NumericValue1ToOperationMap,
  type TestProps
} from '../../types'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const NegativeNumbers = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  const getQuestionAndAnswer = (selectedUserTestTypeScore: number): [string, string] => {
    let question: string
    let answer: string

    const operation = NumericValue1ToOperationMap.get(getRandomNumber(0, 3))
    switch (operation) {
      case AdditionOperation:
        [question, answer] = getQuestionAndAnswerAddition(selectedUserTestTypeScore, true)
        break
      case DivisionOperation:
        [question, answer] = getQuestionAndAnswerDivision(selectedUserTestTypeScore, true)
        break
      case MultiplicationOperation:
        [question, answer] = getQuestionAndAnswerMultiplication(selectedUserTestTypeScore, true)
        break
      default:
        [question, answer] = getQuestionAndAnswerSubtraction(selectedUserTestTypeScore, true)
        break
    }

    console.log(
      'operation', operation.code,
      'question', question,
      'answer', answer)

    return [question, answer]
  }

  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
