import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import { ConfirmationDialog } from '../components/ConfirmationDialog'
import { useNavigate } from 'react-router-dom'
import { produce } from 'immer'
import ReactSlider from 'react-slider'
import styled from 'styled-components'
import { NEW_USER_ID_SENTINEL } from '../constants'
import { Header } from '../components/Header'
import { SettingsSelection } from '../components/SettingsSelection'
import { TestSelection } from '../components/TestSelection'
import { CodeToSettingMap, CodeToTestTypeMap, NumericValue1ToTestSpeedMap } from '../types'
import { useSelectedUser } from '../components/UserBase'
import { UserStats } from '../components/UserStats'
import { saveUsers } from '../utils/saveUsers'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './UserDetails.module.css'

export const UserDetails = (): React.ReactNode => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { usersState, selectedUserState } = useSelectedUser()
  const [users] = usersState
  const [selectedUser, setSelectedUser] = selectedUserState

  const [nameText, setNameText] = useState(selectedUser.id !== NEW_USER_ID_SENTINEL
    ? selectedUser.name
    : '')
  const [testSpeed, setTestSpeed] = useState(selectedUser.id !== NEW_USER_ID_SENTINEL
    ? selectedUser.testSpeed.numericValue1
    : 1)
  const [isUserDeleteConfirmationVisible, setUserDeleteConfirmationVisibility] = useState(false)

  const userTestTypes = new Set<string>(selectedUser.testTypes.map(testType => testType.code))
  const initialTestTypes: Record<string, boolean> = {}
  CodeToTestTypeMap.forEach((value, key) => {
    initialTestTypes[key] = userTestTypes.has(key)
  })
  const [testTypeCheckboxStates, setTestTypeCheckboxStates] = useState(initialTestTypes)

  const userSettings = new Set<string>(selectedUser.settings.map(setting => setting.code))
  const initialSettings: Record<string, boolean> = {}
  CodeToSettingMap.forEach((value, key) => {
    initialSettings[key] = userSettings.has(key)
  })
  const [settingCheckboxStates, setSettingCheckboxStates] = useState(initialSettings)

  const handleTestCheck = (testType: string): void => {
    const matchingTestType = selectedUser.testTypes.find(tt => tt.code === testType)

    setSelectedUser(prevUser => produce(prevUser, draft => {
      if (matchingTestType != null) {
        draft.testTypes = draft.testTypes.filter(tt => tt.code !== testType)
      } else {
        draft.testTypes.push(CodeToTestTypeMap.get(testType))
      }
    }))

    setTestTypeCheckboxStates(prevStates => ({
      ...prevStates,
      [testType]: !(prevStates)[testType]
    }))
  }

  const handleSettingCheck = (setting: string): void => {
    const matchingSetting = selectedUser?.settings?.find(tt => tt.code === setting)

    setSelectedUser(prevUser => produce(prevUser, draft => {
      // Ensure draft.settings is initialized
      if (draft.settings == null) {
        draft.settings = []
      }
      if (matchingSetting != null) {
        draft.settings = draft.settings.filter(tt => tt.code !== setting)
      } else {
        draft.settings.push(CodeToSettingMap.get(setting))
      }
    }))

    setSettingCheckboxStates(prevStates => ({
      ...prevStates,
      [setting]: !(prevStates)[setting]
    }))
  }

  const handleDelete = (): void => {
    setUserDeleteConfirmationVisibility(!isUserDeleteConfirmationVisible)
  }

  const handleUserDeleteCancelClick = (): void => {
    setUserDeleteConfirmationVisibility(false)
  }

  const handleCanceClick = (): void => {
    navigate('/users')
  }

  const handleUserDeleteConfirmClick = (): void => {
    saveUsers(users, selectedUser, true)
    navigate('/users')
  }

  const handleNameChange = (newName: string): void => {
    setNameText(newName)
    setSelectedUser(prevUser => produce(prevUser, draft => {
      draft.name = newName
    }))
  }

  const handleTestSpeedSliderChange = (newTestSpeed): void => {
    const newTestSpeedNumeric = newTestSpeed as number
    setTestSpeed(newTestSpeedNumeric)
    setSelectedUser(prevUser => produce(prevUser, draft => {
      draft.testSpeed = NumericValue1ToTestSpeedMap.get(newTestSpeedNumeric)
    }))
  }

  const handleUserSaveClick = (): void => {
    const userRef = produce(selectedUser, (draft) => {
      if (draft.id === NEW_USER_ID_SENTINEL) {
        // Set uuid for cloned NEW_USER
        draft.id = uuidv4()
      }
    })

    saveUsers(users, userRef)

    navigate('/users')
  }

  // This is the dot that you can grab to set the value for the slider
  const StyledThumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    cursor: grab; `
  const Thumb = (props, state): void => <StyledThumb {...props}></StyledThumb>

  const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => (props.index === 1 ? '#ddd' : '#0f0')};
    border-radius: 999px;`
  const Track = (props, state): void => <StyledTrack {...props} index={state.index} />

  if (isUserDeleteConfirmationVisible) {
    return (
      <ConfirmationDialog
        titleText={t('DeleteConfirmationTitleText', { selectedUserName: selectedUser.name })}
        bodyText={t('DeleteConfirmationBodyText')}
        show={true}
        onHide={handleUserDeleteCancelClick}
        onConfirm={handleUserDeleteConfirmClick} />)
  }

  return (
    <>
      <div className={styles.userContainer}>
        <Header />
        <div className={styles.userCard}>
          <div className={styles.userCardNameInput}>
            <label>{t('Name')}</label>
            <input
              className={styles.bootstrapInput}
              type="text"
              placeholder={t('Enter name')}
              value={nameText}
              onChange={e => { handleNameChange(e.target.value) }}
            />
          </div>
          <SettingsSelection onCheck={handleSettingCheck} settingCheckboxStates={settingCheckboxStates} />
          <TestSelection onCheck={handleTestCheck} testTypeCheckboxStates={testTypeCheckboxStates} />
          <div className={styles.userCardTestSpeedLabel}>{t('Test Speed', { testSpeedDescription: t([NumericValue1ToTestSpeedMap.get(testSpeed).desc]) })}</div>
          <div className={styles.userCardTestSpeed}>
            <ReactSlider
              className={styles.userCardTestSpeedSlider}
              defaultValue={[testSpeed]}
              value={[testSpeed]}
              renderTrack={Track}
              min={0.5}
              max={1.5}
              step={0.25}
              renderThumb={Thumb}
              onChange={(value, index) => { handleTestSpeedSliderChange(value) }}
            />
          </div>
          <div className={styles.userCardButtonContainer}>
            <div className={styles.userCardSaveButton}>
              <Button className={styles.userCardSaveButton} variant="primary" type="submit" onClick={handleUserSaveClick}>
                {t('Save')}
              </Button>
            </div>
            <div className={styles.userCardCancelButton}>
              <Button className={styles.userCardCancelButton} variant="secondary" type="cancel" onClick={handleCanceClick}>
                {t('Cancel')}
              </Button>
            </div>
            { selectedUser.id !== NEW_USER_ID_SENTINEL &&
              <div className={styles.userCardDeleteButton}>
                <Button className={styles.userCardCancelButton} variant="secondary" onClick={handleDelete}>
                  {t('Delete')}
                </Button>
              </div>}
          </div>
          { selectedUser.id !== NEW_USER_ID_SENTINEL &&
              <UserStats selectedUser={selectedUser}/> }
        </div>
      </div>
    </>
  )
}
