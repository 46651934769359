import React from 'react'
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TOOLTIP_SHOW_MILLISECONDS, TOOLTIP_HIDE_MILLISECONDS } from '../constants'
import { CodeToTestTypeMap, SortedTestTypes } from '../types'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './TestSelection.module.css'

interface TestSelectionProps {
  onCheck: (testType: string) => void
  testTypeCheckboxStates: Record<string, boolean>
}

export const TestSelection = ({ onCheck, testTypeCheckboxStates }: TestSelectionProps): React.ReactNode => {
  const { t } = useTranslation()

  const renderTooltip = (props, testType: string): React.ReactNode => (
    <Tooltip className={styles.tooltip} id={`${testType}-tooltip`} {...props}>
      {t(`${CodeToTestTypeMap.get(testType).desc ?? CodeToTestTypeMap.get(testType).code} Tooltip`)}
    </Tooltip>
  )

  return (
    <div className={styles.groupBox}>
      <div className={styles.groupBoxLabel}>{t('Tests')}</div>
      <div className={styles.userCardTestTypes}>
        {SortedTestTypes.map((testType) => (
          <Col className={styles.userCardTestTypeCheckbox} key={testType} xs={6} md={3}>
            <OverlayTrigger
              placement="right"
              delay={{ show: TOOLTIP_SHOW_MILLISECONDS, hide: TOOLTIP_HIDE_MILLISECONDS }}
              overlay={(props) => renderTooltip(props, testType)}
            >
              <div className={styles.userCardTestTypeCheckbox} style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  key={testType}
                  type="checkbox"
                  readOnly
                  id={`checkbox-${testType}`}
                  checked={testTypeCheckboxStates[testType]}
                  onChange={() => { onCheck(testType) }}
                />
                <label htmlFor={`checkbox-${testType}`}>
                  {t(CodeToTestTypeMap.get(testType).desc ?? CodeToTestTypeMap.get(testType).code)}
                </label>
              </div>
            </OverlayTrigger>
          </Col>
        ))}
      </div>
    </div>
  )
}
