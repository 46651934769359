import React from 'react'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './NotFound.module.css'

export const NotFound = (): React.ReactNode => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('NotFoundTitle')}</h1>
    </div>
  )
}
