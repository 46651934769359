import { Addition } from './Addition'
import { BaseConversion } from './BaseConversion'
import { CloseToHundred } from './CloseToHundred'
import { CommonMultiples } from './CommonMultiples'
import { Division } from './Division'
import { Memory } from './Memory'
import { Multiplication } from './Multiplication'
import { NegativeNumbers } from './NegativeNumbers'
import { OrderOfOperations } from './OrderOfOperations'
import { Powers } from './Powers'
import { Rounding } from './Rounding'
import { Subtraction } from './Subtraction'

export const TestMap = {
  Addition,
  BaseConversion,
  CloseToHundred,
  CommonMultiples,
  Division,
  Memory,
  Multiplication,
  NegativeNumbers,
  OrderOfOperations,
  Powers,
  Rounding,
  Subtraction
}
