import React, { createContext, useContext, useState, useEffect } from 'react'
import { SESSION_COUNTDOWN_TIMER_INCREMENT_MS } from '../constants'

// Define the shape of the context's data
interface TimerContextType {
  timeLeft: number
  setTimeLeft: (time: number) => void
  startTimer: (duration: number) => void
}

// Create the context
const TimerContext = createContext<TimerContextType | undefined>(undefined)

// Provide the context to components
export const TimerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState<number>(0)

  const startTimer = (duration: number): void => {
    setTimeLeft(duration)
  }

  // This effect will handle the countdown
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0))
    }, SESSION_COUNTDOWN_TIMER_INCREMENT_MS)

    return () => { clearInterval(timerId) }
  }, [])

  return (
    <TimerContext.Provider value={{ timeLeft, setTimeLeft, startTimer }}>
      {children}
    </TimerContext.Provider>
  )
}

// Hook to use the timer context
export const useTimer = (): React.ReactNode => {
  const context = useContext(TimerContext)
  if (context == null) {
    throw new Error('useTimer must be used within a TimerProvider')
  }
  return context
}
