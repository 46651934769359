import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { produce } from 'immer'
import { NEW_USER_ID_SENTINEL } from '../constants'
import { Medium, type User } from '../types'
import { getSavedUsers } from '../utils/getSavedUsers'
import { useTranslation } from 'react-i18next'

type UseSavedUsersResult = [User[], (newSavedUsers: User[]) => void]

const NEW_USER: User = {
  id: NEW_USER_ID_SENTINEL,
  name: '',
  testSpeed: Medium,
  testTypeScores: [],
  testTypeScores2: {},
  testTypes: [],
  settings: [],
  testResults: [],
  testTypeResults: {}
}

export const useSavedUsers = (): UseSavedUsersResult => {
  const { t } = useTranslation()
  const users = getSavedUsers()
  users.sort((a, b) => a.name.localeCompare(b.name))

  // Create user by copying the NEW_USER, setting the name to the translation
  users.push(produce(NEW_USER, (draft) => {
    draft.name = t('New user')
  }))

  return useState(users)
}

type SelectedUserResult = [User, (newSelectedUser: User) => void]
interface UseSelectedUserResult { usersState: UseSavedUsersResult, selectedUserState: SelectedUserResult }

export const useSelectedUser = (): UseSelectedUserResult => {
  const { t } = useTranslation()
  const users = getSavedUsers()
  const usersState = useState(users)

  const { id } = useParams()
  // @ts-expect-error(a specific user will always be in the users array)
  const savedUser: User = users.find(user => user.id === id)
  // If there is no saved user, create a new one, by copying the NEW_USER
  const selectedUserState = useState(savedUser ?? produce(NEW_USER, (draft) => {
    draft.name = t('New user')
  }))

  return { usersState, selectedUserState }
}
