import { LOCAL_STORAGE_USERS_KEY } from '../constants'
import { type User } from './types'

const conditionallyInitializeTestTypeScores2 = (user: User): void => {
  if (user.testTypeScores.length > 0) {
    const testTypeScores2: TestTypeScores = {}
    user.testTypeScores.forEach((tts) => {
      testTypeScores2[tts.testType.code] = tts.score
    })
    user.testTypeScores2 = testTypeScores2
    user.testTypeScores = []
  }
}

const conditionallyInitializeTestTypeResults = (user: User): void => {
  if (user.testTypeScores.length > 0) {
    const testTypeScores2: TestTypeScores = {}
    user.testTypeScores.forEach((tts) => {
      testTypeScores2[tts.testType.code] = tts.score
    })
    user.testTypeScores2 = testTypeScores2
    user.testTypeScores = []
  }

  if (user.testResults.length > 0) {
    const testTypeResults: TestTypeResults = {}
    user.testResults.forEach((ttr) => {
      if (testTypeResults[ttr.testType.code] == null) {
        testTypeResults[ttr.testType.code] = []
      }
      testTypeResults[ttr.testType.code].push(ttr)
    })
    user.testTypeResults = testTypeResults
    user.testResults = []
  }
}

export const getSavedUsers = (): User[] => {
  const userData = localStorage.getItem(LOCAL_STORAGE_USERS_KEY as string)
  const users: User[] = []

  if (userData != null) {
    const parsedUsers: User[] = JSON.parse(userData, (key, value: string | number | Date) => {
      if (key === 'testDate') {
        return new Date(value) // Convert the value to a Date object
      }
      return value
    }).filter(user => user != null)

    parsedUsers.forEach(user => {
      conditionallyInitializeTestTypeScores2(user)
      conditionallyInitializeTestTypeResults(user)

      // Ensure each user has a settings array if it's missing
      if (!('settings' in user)) {
        user.settings = [] // Initialize settings as an empty array if it's missing
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    users.push(...parsedUsers)
  }

  return users
}
