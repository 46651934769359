import { RoundingDirection } from '../types'

export const getMaxDigits = (
  selectedUserTestTypeScore: number,
  roundingDirection: RoundingDirection = RoundingDirection.Nearest
): number => {
  const unprocessedDigits = Math.log(selectedUserTestTypeScore)

  let digits
  if (unprocessedDigits < 1) {
    digits = 1
  } else {
    switch (roundingDirection) {
      case RoundingDirection.Up:
        digits = Math.ceil(unprocessedDigits)
        break
      case RoundingDirection.Down:
        digits = Math.floor(unprocessedDigits)
        break
      case RoundingDirection.Nearest:
      default:
        digits = Math.round(unprocessedDigits)
        break
    }
  }

  console.log(
    'selectedUserTestTypeScore', selectedUserTestTypeScore,
    'unprocessedDigits', unprocessedDigits,
    'digits', digits
  )

  return digits
}
