export const getMaxNumber = (selectedUserTestTypeScore: number): number => {
  const unprocessedDigits = Math.log(selectedUserTestTypeScore)
  const digits = unprocessedDigits < 1 ? 1 : Math.round(unprocessedDigits)
  // The first "digit" is for 0 - 10. The subsequent digits (x) add x to 10
  const maxNumber = 10 + digits - 1
  console.log(
    'selectedUserTestTypeScore', selectedUserTestTypeScore,
    'unprocessedDigits', unprocessedDigits,
    'digits', digits,
    'maxNumber', maxNumber)
  return maxNumber
}
