import React from 'react'
import { type Code, Correct, Incorrect, type OnCompleteResult } from '../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { StatusBar } from '../components/StatusBar'
// @ts-expect-error(import of css styles for transpilation)
import styles from './ShowResult.module.css'

interface ShowResultProps {
  selectedUser: User
  onCompleteResult: OnCompleteResult
  testResult: Code
  testType: string
}

export const ShowResult = ({ selectedUser, onCompleteResult, testResult, testType }: ShowResultProps): React.ReactNode => {
  return (
    <>
      <div className={styles.testResultContainer}>
        {testResult === Correct &&
          <div className={styles.testResultCorrectContainer}>
            <div className={styles.testResultCorrect}>
              <FontAwesomeIcon className={styles.testResultCorrect} icon={faCheck} />
            </div>
          </div> }
        {testResult === Incorrect &&
          <div className={styles.testResultIncorrectContainer}>
            <div className={styles.testResultIncorrect}>
              <FontAwesomeIcon className={styles.testResultIncorrect} icon={faXmark} />
            </div>
            <div className={styles.testResultIncorrectQuestionAnswer}>{onCompleteResult.question} = {onCompleteResult.answer}</div>
          </div> }
      </div>
      <StatusBar selectedUser={selectedUser} testType={testType} testResult={testResult} />
    </>
  )
}
