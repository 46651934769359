import React, { useEffect, useState } from 'react'
import { NUMBER_CORRECT_TO_HEAR_STREAK_SOUND_EFFECT } from '../constants'
import { type Code, CodeToTestTypeMap, Correct, Incorrect, type User } from '../types'
import useSound from 'use-sound'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './StatusBar.module.css'

interface StatusBarProps {
  selectedUser: User
  testType: string
  testResult: Code
}

export const StatusBar = ({ selectedUser, testType, testResult }: StatusBarProps): React.ReactNode => {
  const { t } = useTranslation()
  const [playCorrect] = useSound('/correct.mp3')
  const [playIncorrect] = useSound('/incorrect.mp3')
  const [playStreak] = useSound('/streak.mp3')
  const [flashType, setFlashType] = useState('')
  const [topFlashType, setTopFlashType] = useState('')

  useEffect(() => {
    const flashType: string = testResult?.code ?? ''
    setFlashType(flashType)

    if (selectedUser.currentTestStreak === selectedUser.topTestStreak) {
      setTopFlashType(flashType)
    }

    if (testResult === Correct) {
      const hasStreak = selectedUser.currentTestStreak > 0 &&
        selectedUser.currentTestStreak % NUMBER_CORRECT_TO_HEAR_STREAK_SOUND_EFFECT === 0
      if (hasStreak) {
        playStreak()
      } else {
        playCorrect()
      }
    } else if (testResult === Incorrect) {
      playIncorrect()
    }

    const timeoutId = setTimeout(() => {
      setFlashType('')
      setTopFlashType('')
    }, 2000) // Reset after animation

    return () => { clearTimeout(timeoutId) }
  }, [selectedUser, testResult, setFlashType, setTopFlashType, playCorrect, playIncorrect, playStreak])

  const testTypeCode = CodeToTestTypeMap.get(testType)

  return (
    <div className={styles.statusBar} key={t((testTypeCode.desc ?? testTypeCode.code))}>
      <div className={`${styles[flashType]}`}>
        {t('Test type score', { testType: t(testTypeCode.desc ?? testTypeCode.code), score: selectedUser.testTypeScores2[testType] })}
      </div>
      <div className={`${styles[flashType]}`}>
        {t('Current streak 2', { testStreak: selectedUser.currentTestStreak })}
      </div>
      <div className={`${styles[topFlashType]}`}>
        {t('Top streak 2', { testStreak: selectedUser.topTestStreak })}
      </div>
    </div>
  )
}
