import React, { useEffect } from 'react'
import { DELETE_ANSWER_CHARACTER_SENTINEL } from '../constants'
import { type NumericKeypadProps } from '../types'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './NumericKeypad.module.css'

export const NumericKeypad = ({ onKeyPress, isShowHexKeys = false, isShowDecimalPoint = false, onStop }: NumericKeypadProps): React.ReactNode => {
  const { t } = useTranslation()

  const handleKeyDown = (event: KeyboardEvent): void => {
    const keyActions = {
      1: () => { onKeyPress('1') },
      2: () => { onKeyPress('2') },
      3: () => { onKeyPress('3') },
      4: () => { onKeyPress('4') },
      5: () => { onKeyPress('5') },
      6: () => { onKeyPress('6') },
      7: () => { onKeyPress('7') },
      8: () => { onKeyPress('8') },
      9: () => { onKeyPress('9') },
      0: () => { onKeyPress('0') },
      '-': () => { onKeyPress('-') },
      '.': () => { isShowDecimalPoint && onKeyPress('.') },
      a: () => { isShowHexKeys && onKeyPress('a') },
      b: () => { isShowHexKeys && onKeyPress('b') },
      c: () => { isShowHexKeys && onKeyPress('c') },
      d: () => { isShowHexKeys && onKeyPress('d') },
      e: () => { isShowHexKeys && onKeyPress('e') },
      f: () => { isShowHexKeys && onKeyPress('f') },
      Backspace: () => { onKeyPress(DELETE_ANSWER_CHARACTER_SENTINEL) },
      Delete: () => { onKeyPress(DELETE_ANSWER_CHARACTER_SENTINEL) },
      Escape: () => { onStop() } // Using 'Escape' key to trigger stop
    }

    // Execute the action if the key is in the actions object
    const action = keyActions[event.key]
    if (action != null) {
      action()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isShowHexKeys, onKeyPress, onStop])

  return (
    <>
      <div className={styles.keypadContainer}>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('1') }}>1</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('2') }}>2</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('3') }}>3</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('4') }}>4</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('5') }}>5</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('6') }}>6</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('7') }}>7</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('8') }}>8</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('9') }}>9</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('0') }}>0</button>
        <button className={styles.keypadButton} onClick={() => { onKeyPress('-') }}>-</button>
        {isShowDecimalPoint && <button className={styles.keypadButton} onClick={() => { onKeyPress('.') }}>.</button>}
        {isShowHexKeys && <button className={styles.keypadButton} onClick={() => { onKeyPress('a') }}>a</button>}
        {isShowHexKeys && <button className={styles.keypadButton} onClick={() => { onKeyPress('b') }}>b</button>}
        {isShowHexKeys && <button className={styles.keypadButton} onClick={() => { onKeyPress('c') }}>c</button>}
        {isShowHexKeys && <button className={styles.keypadButton} onClick={() => { onKeyPress('d') }}>d</button>}
        {isShowHexKeys && <button className={styles.keypadButton} onClick={() => { onKeyPress('e') }}>e</button>}
        {isShowHexKeys && <button className={styles.keypadButton} onClick={() => { onKeyPress('f') }}>f</button>}
        <button
          className={styles.keypadDeleteButton}
          onClick={() => { onKeyPress(DELETE_ANSWER_CHARACTER_SENTINEL) }}>
            {t('Delete')}
        </button>
        <button
          className={styles.keypadStopButton}
          onClick={onStop}
          tabIndex={1}>
            {t('Stop')}
        </button>
      </div>
    </>
  )
}
