import { LOCAL_STORAGE_USERS_KEY } from '../constants'
import { type User } from './types'

export const saveUsers = (users: User[], toAddOrRemoveUser: User, remove: boolean = false): void => {
  users = users.filter(user => toAddOrRemoveUser?.id !== user.id)
  if (!remove) {
    users.push(toAddOrRemoveUser)
  }
  const userData = JSON.stringify(users, (key, value) => {
    if (key === 'testDate' && value instanceof Date) {
      return value.toISOString() // Serialize the Date object to ISO string
    }
    return value
  })

  localStorage.setItem(LOCAL_STORAGE_USERS_KEY as string, userData)
}
