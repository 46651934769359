import React from 'react'
import { Test } from './Test'
import { type TestProps } from '../../types'
import { getMaxNumber } from '../../utils/getMaxNumber'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const getQuestionAndAnswer = (selectedUserTestTypeScore: number, includeNegativeNumbers: boolean = false): [string, string] => {
  const maxNumber = getMaxNumber(selectedUserTestTypeScore)
  let addend1 = getRandomNumber(0, maxNumber)
  let addend2 = getRandomNumber(0, maxNumber)

  addend1 *= includeNegativeNumbers ? -1 : 1
  addend2 *= includeNegativeNumbers ? -1 : 1

  const question = addend1.toString() + ' + ' + addend2.toString()
  const answer = (addend1 + addend2).toString()

  console.log(
    'addend1', addend1,
    'addend2', addend2,
    'question', question,
    'answer', answer)

  return [question, answer]
}

export const Addition = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
