import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './Landing.module.css'

export const Landing = (): React.ReactNode => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handlePlayClick = (): void => {
    navigate('/users')
  }

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <img src='/Mathniac-branding.svg' alt='Mathniac Branding' />
        <Button className={styles.play} variant='success' onClick={() => { handlePlayClick() }}>{t('Play')}</Button>
      </div>
      <div className={styles.content}>
        <div className={styles.infoSections}>
          <div className={styles.news}>
            <div className={styles.newsTitle}>{t('News Title')}</div>
            <div className={styles.newsText}>{t('News Text')}</div>
          </div>
          <div className={styles.languages}>
            <div className={styles.languagesTitle}>{t('Languages Title')}</div>
            <div className={styles.languagesText}>{t('Languages Text')}</div>
          </div>
          <div className={styles.about}>
            <div className={styles.aboutTitle}>{t('About Title')}</div>
            <div className={styles.aboutText}>{t('About Text')}</div>
          </div>
          <div className={styles.privacy}>
            <div className={styles.privacyTitle}>{t('Privacy Title')}</div>
            <div className={styles.privacyText}>{t('Privacy Text')}</div>
          </div>
        </div>
        <div className={styles.testimonials}>
          <div className={styles.testimonialsTitle}>{t('Testimonials Title')}</div>
          <div className={styles.testimonial}>
            <div className={styles.testimonialText}>{t('Testimonial 1 Text')}</div>
            <div className={styles.authorInfo}>
              <div className={styles.testimonialAuthor}>{t('Testimonial 1 Author')}</div>
              <div className={styles.testimonialAuthorRole}>{t('Testimonial 1 Role')}</div>
            </div>
          </div>
          <div className={styles.testimonial}>
            <div className={styles.testimonialText}>{t('Testimonial 2 Text')}</div>
            <div className={styles.authorInfo}>
              <div className={styles.testimonialAuthor}>{t('Testimonial 2 Author')}</div>
              <div className={styles.testimonialAuthorRole}>{t('Testimonial 2 Role')}</div>
            </div>
          </div>
          <div className={styles.testimonial}>
            <div className={styles.testimonialText}>{t('Testimonial 3 Text')}</div>
            <div className={styles.authorInfo}>
              <div className={styles.testimonialAuthor}>{t('Testimonial 3 Author')}</div>
              <div className={styles.testimonialAuthorRole}>{t('Testimonial 3 Role')}</div>
            </div>
          </div>
        </div>
        <div className={styles.credits}>
          <div className={styles.creditsTitle}>{t('Credits Title')}</div>
          <div className={styles.creditsText}>{t('Credits Text')}</div>
          <div className={styles.credit}>
            <div className={styles.creditText}>
              {t('Credit 1 Text')} <a href={t('Credit 1 Link')}>{t('Credit 1 Link')}</a>
            </div>
            <div className={styles.creditLicenseInfo}>
              <div className={styles.creditLicense}>{t('Credit 1 License')}</div>
            </div>
          </div>
          <div className={styles.credit}>
            <div className={styles.creditText}>
              {t('Credit 2 Text')} <a href={t('Credit 2 Link')}>{t('Credit 2 Link')}</a>
            </div>
            <div className={styles.creditLicenseInfo}>
              <div className={styles.creditLicense}>{t('Credit 2 License')}</div>
            </div>
          </div>
          <div className={styles.credit}>
            <div className={styles.creditText}>
              {t('Credit 3 Text')} <a href={t('Credit 3 Link')}>{t('Credit 3 Link')}</a>
            </div>
            <div className={styles.creditLicenseInfo}>
              <div className={styles.creditLicense}>{t('Credit 3 License')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
