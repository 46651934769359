import React from 'react'
import { Test } from './Test'
import {
  AdditionOperation,
  DivisionOperation,
  MultiplicationOperation,
  NumericValue1ToOperationMap,
  RoundingDirection,
  SubtractionOperation,
  type TestProps
} from '../../types'
import { getMaxDigits } from '../../utils/getMaxDigits'
import { getMaxNumber } from '../../utils/getMaxNumber'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const OrderOfOperations = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  const calculateOperation = (operation: Code, a: number, b: number): number => {
    switch (operation.code) {
      case AdditionOperation.code:
        return a + b
      case SubtractionOperation.code:
        return a - b
      case MultiplicationOperation.code:
        return a * b
      case DivisionOperation.code:
        return a / b
      default:
        throw new Error(`Unknown operation: ${operation.code}`)
    }
  }

  const generateInitialExpression = (numberOfOperations: number, maxNumber: number): [string[], Code[]] => {
    const numberOfElements = numberOfOperations * 2 + 1
    const expression: string[] = []
    const operations: Code[] = new Array(numberOfElements).fill(null)

    for (let i = 0; i < numberOfElements; i++) {
      if (i % 2 === 0) {
        expression[i] = getRandomNumber(1, maxNumber).toString()
      } else {
        const operation = NumericValue1ToOperationMap.get(getRandomNumber(0, 3))
        operations[i] = operation
        expression[i] = operation.value1
      }
    }

    return [expression, operations]
  }

  const isValidExpression = (expression: string[], operations: Code[]): boolean => {
    const [precedence2Expression1, precedence2Operations1, isValid1] = calculateExpressionPrecedence2(expression, operations)
    if (!isValid1) {
      return false
    }

    const [precedence2Expression2, precedence2Operations2, isValid2] = calculateExpressionPrecedence1(precedence2Expression1, precedence2Operations1)
    if (!isValid2) {
      return false
    }

    return true
  }

  const calculateExpressionPrecedence2 = (expression: string[], operations: Code[]): [string[], string[], boolean] => {
    const tempExpression = [...expression]
    const tempOperations = [...operations]

    // Compute multiplication and division (precedence 2)
    for (let i = 1; i < tempExpression.length - 1; i += 2) {
      const operation = tempOperations[i]
      if (operation?.numericValue2 === 2) {
        const left = parseInt(tempExpression[i - 1])
        const right = parseInt(tempExpression[i + 1])
        const result = calculateOperation(operation, left, right)

        if (!Number.isInteger(result)) {
          return [null, null, false]
        }

        tempExpression.splice(i - 1, 3, result.toString())
        tempOperations.splice(i - 1, 3, null)
        i -= 2
      }
    }

    return [tempExpression, tempOperations, true]
  }

  const calculateExpressionPrecedence1 = (expression: string[], operations: Code[]): [string[], string[], boolean] => {
    const tempExpression = [...expression]
    const tempOperations = [...operations]

    // Compute addition and subtraction (precedence 1)
    for (let i = 1; i < tempExpression.length - 1; i += 2) {
      const operation = tempOperations[i]
      console.log('operation', operation)
      if (operation?.numericValue2 === 1) {
        const left = parseInt(tempExpression[i - 1])
        const right = parseInt(tempExpression[i + 1])
        const result = calculateOperation(operation, left, right)

        if (result < 0) {
          return [null, null, false]
        }

        tempExpression.splice(i - 1, 3, result.toString())
        tempOperations.splice(i - 1, 3, null)
        i -= 2
      }
    }

    return [tempExpression, tempOperations, true]
  }

  const generateExpression = (numberOfOperations: number, maxNumber: number): [string, number] => {
    let finalOperations
    let finalExpression

    while (true) {
      const [expression, operations] = generateInitialExpression(numberOfOperations, maxNumber)

      console.log(
        'operations', operations,
        'expression', expression
      )

      if (isValidExpression(expression, operations)) {
        finalOperations = operations
        finalExpression = expression
        break
      }
    }

    const question = finalExpression.join(' ')
    const [precedence2Expression, precedence2Operations] = calculateExpressionPrecedence2(finalExpression, finalOperations)
    const [precedence1Expression, precedence1Operations] = calculateExpressionPrecedence1(precedence2Expression, precedence2Operations)
    const answer = precedence1Expression[0]

    console.log(
      'finalExpression', finalExpression,
      'finalOperations', finalOperations
    )

    return [question, answer]
  }

  const getQuestionAndAnswer = (): [string, string] => {
    const maxNumber = getMaxNumber(selectedUserTestTypeScore) / 2
    const maxDigit = getMaxDigits(selectedUserTestTypeScore, RoundingDirection.Down)
    const numberOfOperations = maxDigit
    const [question, answer] = generateExpression(numberOfOperations, maxNumber)

    console.log(
      'selectedUserTestTypeScore', selectedUserTestTypeScore,
      'maxDigit', maxDigit,
      'maxNumber', maxNumber,
      'numberOfOperations', numberOfOperations,
      'question', question,
      'answer', answer
    )

    return [question, answer.toString()]
  }

  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
