import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './MinutesEntryDialog.module.css'

interface MinutesEntryDialogProps {
  show: boolean
  onHide: () => void
  onSubmit: (minutes: number) => void
}

export const MinutesEntryDialog: React.FC<MinutesEntryDialogProps> = ({
  show,
  onHide,
  onSubmit
}) => {
  const { t } = useTranslation()
  const [minutes, setMinutes] = useState<number>(0)

  const handleSubmit = (): void => {
    if (minutes > 0) {
      onSubmit(minutes)
    } else {
      alert(t('Minutes entry validation'))
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>{t('Minutes entry title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Form.Group>
          <Form.Label>{t('Minutes entry label')}</Form.Label>
          <Form.Control
            type="number"
            value={minutes.toString()}
            onChange={(e) => { setMinutes(parseInt(e.target.value, 10)) }}
            min="1"
            placeholder={t('Minutes entry placeholder')}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button className={styles.modalButtonCancel} variant="secondary" onClick={onHide}>
          {t('Minutes entry skip')}
        </Button>
        <Button
          className={styles.modalButtonConfirm}
          variant="primary"
          onClick={handleSubmit}
          disabled={minutes == null || minutes <= 0}
        >
            {t('Minutes entry start')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
