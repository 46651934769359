import { CodeToTestTypeMap, type User } from '../types'

export const getAdjustedMilliseconds = (selectedUser: User, testType: string, originalMs: number): number => {
  const testTypeCode: Code = CodeToTestTypeMap.get(testType)
  const testTypeSpecificAdjustedMsMultiplier = testTypeCode.numericValue1 ?? 1
  // @ts-expect-error(test speed and numeric value 1 will both always be defined for a user)
  const adjustedMs = originalMs * testTypeSpecificAdjustedMsMultiplier / selectedUser.testSpeed.numericValue1

  /*
  console.log(
    'testType', testType,
    'testTypeCode', testTypeCode,
    'testTypeSpecificAdjustedMsMultiplier', testTypeSpecificAdjustedMsMultiplier,
    'originalMs', originalMs,
    'testSpeed', selectedUser.testSpeed.code,
    'adjustedMs', adjustedMs)
  */

  return adjustedMs
}
