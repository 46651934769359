import React from 'react'
import {
  MIN_NON_DECIMAL_NUMBER_BASE,
  MAX_NON_DECIMAL_NUMBER_BASE
} from '../../constants'
import { Test } from './Test'
import { type Code, Decimal, NumericValue1ToNumberBaseMap, type TestProps } from '../../types'
import { getMaxNumber } from '../../utils/getMaxNumber'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const BaseConversion = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  const getQuestionAndAnswer = (): [string, string] => {
    const maxNumber = getMaxNumber(selectedUserTestTypeScore)
    const decimalNumber = getRandomNumber(1, maxNumber)

    const numberBaseValue = getRandomNumber(MIN_NON_DECIMAL_NUMBER_BASE, MAX_NON_DECIMAL_NUMBER_BASE)
    // @ts-expect-error(numberBaseValue will always be in the map)
    const numberBase: Code = NumericValue1ToNumberBaseMap.get(numberBaseValue)
    const nonDecimalNumber = decimalNumber.toString(numberBase.numericValue2)

    const convertFromDecimal = getRandomNumber(0, 1) === 0

    const question = convertFromDecimal
      ? `${decimalNumber.toString()} (${Decimal.code}) => ? (${numberBase.code})`
      : `${nonDecimalNumber.toString()} (${numberBase.code}) = ? (${Decimal.code})`
    const answer = convertFromDecimal
      ? nonDecimalNumber.toString()
      : decimalNumber.toString()

    console.log(
      'numberBase', numberBase,
      'decimalNumber', decimalNumber,
      'nonDecimalNumber', nonDecimalNumber,
      'convertFromDecimal', convertFromDecimal,
      'question', question,
      'answer', answer)

    return [question, answer]
  }

  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      isShowHexKeys={true}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
