import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
// @ts-expect-error(import of css styles for transpilation)
import styles from './ConfirmationDialog.module.css'

interface ConfirmationDialogProps {
  titleText: string
  bodyText: string
  show: boolean
  onHide: () => void
  onConfirm: () => void
}

export const ConfirmationDialog = ({
  titleText,
  bodyText,
  show,
  onHide,
  onConfirm
}: ConfirmationDialogProps): React.ReactNode => {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className="{styles.modalHeader}" closeButton>
        <Modal.Title className={styles.modalTitle}>{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>{bodyText}</Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button className={styles.modalButtonCancel} variant="secondary" onClick={onHide}>
          {t('Cancel')}
        </Button>
        <Button className={styles.modalButtonConfirm} variant="danger" onClick={onConfirm}>
          {t('Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
