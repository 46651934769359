import {
  MAX_NON_DECIMAL_NUMBER_BASE,
  MIN_NON_DECIMAL_NUMBER_BASE
} from './constants'

export interface Code {
  code: string
  desc?: string
  value1?: string
  value2?: string
  numericValue1?: number
  numericValue2?: number
}

export const Correct: Code = { code: 'Correct', numericValue1: 2 }
export const Incorrect: Code = { code: 'Incorrect', numericValue1: -1 }

export const Low: Code = { code: 'Low', desc: 'Low', numericValue1: 0.5 }
export const MediumLow: Code = { code: 'MediumLow', desc: 'Medium Low', numericValue1: 0.75 }
export const Medium: Code = { code: 'Medium', desc: 'Medium', numericValue1: 1 }
export const MediumHigh: Code = { code: 'MediumHigh', desc: 'Medium High', numericValue1: 1.25 }
export const High: Code = { code: 'High', desc: 'High', numericValue1: 1.5 }
export const NumericValue1ToTestSpeedMap = new Map<number, Code>([
  [Low.numericValue1, Low],
  [MediumLow.numericValue1, MediumLow],
  [Medium.numericValue1, Medium],
  [MediumHigh.numericValue1, MediumHigh],
  [High.numericValue1, High]
])

export const TimedTests: Code = { code: 'TimedTests', desc: 'Timed Tests' }
export const CodeToSettingMap = new Map<string, Code>([
  [TimedTests.code, TimedTests]
])
export const SortedSettings = [...CodeToSettingMap.keys()].sort()

// The Code.numericValue1 value will be used as a multiplier for the test time - so as a way to give
// more or less time for specific test types
export const Addition: Code = { code: 'Addition' }
export const BaseConversion: Code = { code: 'BaseConversion', desc: 'Base Conversion' }
export const CloseToHundred: Code = { code: 'CloseToHundred', desc: 'Close To Hundred' }
export const CommonMultiples: Code = { code: 'CommonMultiples', desc: 'Common Multiples' }
export const Division: Code = { code: 'Division' }
export const Memory: Code = { code: 'Memory' }
export const Multiplication: Code = { code: 'Multiplication' }
export const NegativeNumbers: Code = { code: 'NegativeNumbers', desc: 'Negative Numbers' }
export const OrderOfOperations: Code = { code: 'OrderOfOperations', desc: 'Order Of Operations', numericValue1: 3 }
export const Powers: Code = { code: 'Powers' }
export const Rounding: Code = { code: 'Rounding' }
export const Subtraction: Code = { code: 'Subtraction' }
export const CodeToTestTypeMap = new Map<string, Code>([
  [Addition.code, Addition],
  [BaseConversion.code, BaseConversion],
  [CloseToHundred.code, CloseToHundred],
  [CommonMultiples.code, CommonMultiples],
  [Division.code, Division],
  [Memory.code, Memory],
  [Multiplication.code, Multiplication],
  [OrderOfOperations.code, OrderOfOperations],
  [Rounding.code, Rounding],
  [Subtraction.code, Subtraction]
])
export const SortedTestTypes = [...CodeToTestTypeMap.keys()].sort()

export const Binary: Code = { code: 'Binary', numericValue1: MIN_NON_DECIMAL_NUMBER_BASE, numericValue2: 2 }
export const Octal: Code = { code: 'Octal', numericValue1: 1, numericValue2: 8 }
export const Hexadecimal: Code = { code: 'Hexadecimal', numericValue1: MAX_NON_DECIMAL_NUMBER_BASE, numericValue2: 16 }
export const Decimal: Code = { code: 'Decimal', numericValue1: 3, numericValue2: 10 }
export const NumericValue1ToNumberBaseMap = new Map<number, Code>([
  [Binary.numericValue1, Binary],
  [Octal.numericValue1, Octal],
  [Hexadecimal.numericValue1, Hexadecimal],
  [Decimal.numericValue1, Decimal]
])

// Numeric value 2 is the operation precedence
export const AdditionOperation: Code = { code: 'Addition', value1: '+', numericValue1: 0, numericValue2: 1 }
export const DivisionOperation: Code = { code: 'Division', value1: '÷', numericValue1: 1, numericValue2: 2 }
export const MultiplicationOperation: Code = { code: 'Multiplication', value1: '\u00D7', numericValue1: 2, numericValue2: 2 }
export const SubtractionOperation: Code = { code: 'Subtraction', value1: '-', numericValue1: 3, numericValue2: 1 }
export const NumericValue1ToOperationMap = new Map<number, Code>([
  [AdditionOperation.numericValue1, AdditionOperation],
  [DivisionOperation.numericValue1, DivisionOperation],
  [MultiplicationOperation.numericValue1, MultiplicationOperation],
  [SubtractionOperation.numericValue1, SubtractionOperation]
])

export interface UserTestResult {
  testType: Code
  testDate: Date
  userScore: number
  question: string
  answer: string
  userAnswer: string
  result: Code
}

export interface TestTypeScore {
  testType: Code
  score: number
}

export interface TestTypeScore2 {
  testType: string
  score: number
}

export type TestTypeScores = Record<string, number>
export type TestTypeResults = Record<string, UserTestResult[]>

export interface User {
  id: string
  name: string
  currentTestStreak: number
  currentDayStreak: number
  topTestStreak: number
  topDayStreak: number
  lastPlayDate: Date
  testSpeed: Code
  testTypeScores: TestTypeScore[]
  testTypeScores2: TestTypeScores
  testTypes: Code[]
  settings: Code[]
  testResults: UserTestResult[]
  testTypeResults: TestTypeResults
}

export interface OnCompleteResult {
  question: string
  answer: string
  userAnswer: string
}

export interface TestProps {
  selectedUser?: User
  selectedUserTestTypeScore: number
  isShowQuestion: boolean
  isClearQuestion: boolean
  isCheckAnswer: boolean
  willClearQuestion: boolean
  isShowHexKeys: boolean
  isShowDecimalPoint: boolean
  questionPrompt: string
  onComplete: (result: OnCompleteResult) => void
  onStop: () => void
  onGetQuestionAndAnswer: () => [string, string, string, number]
}

export interface NumericKeypadProps {
  onKeyPress: (key: string) => void
  isShowHexKeys?: boolean
  isShowDecimalPoint?: boolean
  onStop: () => void
}

export enum RoundingDirection {
  Up = 'up',
  Down = 'down',
  Nearest = 'nearest'
}
