import React from 'react'
import { Test } from './Test'
import { type TestProps } from '../../types'
import { getMaxNumber } from '../../utils/getMaxNumber'
import { getRandomNumber } from '../../utils/getRandomNumber'

export const getQuestionAndAnswer = (selectedUserTestTypeScore: number, includeNegativeNumbers: boolean = false): [string, string] => {
  const maxNumber = getMaxNumber(selectedUserTestTypeScore)
  let minuend = getRandomNumber(0, maxNumber)
  let subtrahend = getRandomNumber(0, minuend)

  minuend *= includeNegativeNumbers ? -1 : 1
  subtrahend *= includeNegativeNumbers ? -1 : 1

  const question = minuend.toString() + ' - ' + subtrahend.toString()
  const answer = (minuend - subtrahend).toString()

  console.log(
    'minuend', minuend,
    'subtrahend', subtrahend,
    'question', question,
    'answer', answer)

  return [question, answer]
}

export const Subtraction = ({
  selectedUserTestTypeScore,
  isShowQuestion,
  isCheckAnswer,
  onComplete,
  onStop
}: TestProps): React.ReactNode => {
  return (
    <Test
      selectedUserTestTypeScore={selectedUserTestTypeScore}
      isShowQuestion={isShowQuestion}
      isClearQuestion={false}
      isCheckAnswer={isCheckAnswer}
      onComplete={onComplete}
      onStop={onStop}
      onGetQuestionAndAnswer={getQuestionAndAnswer}
    />
  )
}
